import "./Loader.css";
import { motion } from "framer-motion";

const draw = {
  hidden: { pathLength: 0, opacity: 0, pathOffset: 0 },
  visible: () => {
    const delay = 0.1;
    return {
      pathLength: 1,
      pathOffset: 2,
      opacity: 1,
      alignSelf: 'center',
      transition: {
        pathLength: { delay,  duration: 1, bounce: 0, repeat: Infinity, repeatType: "reverse", },
        pathOffset: { delay, duration: 1, bounce: 0, repeat: Infinity, repeatType: "mirror", },
        opacity: { delay, duration: 0.01 }
      }
    };
  }
};

const Loader = ({ active, style, color }) =>  {
  return (
    <motion.svg
      initial="hidden"
      animate={active ? "visible" : "none"}
      width="30px"
      height="2rem"
      className={`${active ? "visible" : "hidden"} svg-style`}
      style={style}
    >
      <motion.circle
        r="10"
        stroke={color || "#000"}
        variants={draw}
        cx="50%"
        cy="50%"
      />
    </motion.svg>
  );
}

export default Loader;
