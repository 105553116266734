import { useEffect, useRef, useState } from 'react';
import { Html5Qrcode, Html5QrcodeScannerState, Html5QrcodeSupportedFormats} from 'html5-qrcode';
import Loader from '../Loader/Loader';

import '../QRScanner/QRScanner.css';

 // This component should be rendered conditionally with a state,
 // and the setState for such state should be passed here as setShowScanner
 // Ex: {state && <QRScanner setShowScanner={state} fillData={fillData}/>}
const QRScanner = ({ fillData, setShowScanner }) => {
  const scannerRef = useRef(null);
  const qrImageRef = useRef(null);

  const [errorMsg, setErrorMsg] = useState('');
  const [isOpening, setIsOpening] = useState(false);

  // This closes the scanning of the camera
  const closeQRScanner = async () => {
    const scannerState = scannerRef.current?.getState(); // UNKNOWN = 0, NOT_STARTED = 1, SCANNING = 2, PAUSED = 3
    if (
      scannerState === Html5QrcodeScannerState.SCANNING ||
      scannerState === Html5QrcodeScannerState.PAUSED
    ) {
      try {
        await scannerRef.current.stop();
      } catch (err) {
        console.error('Error al cerrar el escáner.');
      }
    }
  };

  useEffect(() => {
    const initializeScanner = async () => {
      await closeQRScanner(); // Ensures no duplicate scanner
      // This library supports multiple formats, if not defined it will support all of them,
      // since we are only working with QRs we can specify to only support QRs an improve performance
      scannerRef.current = new Html5Qrcode('reader', { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ] });
    };

    initializeScanner();

    let lastWidth = window.innerWidth;

    // Debouncing the handleResize function to prevent rapid invocations
    const handleResize = debounce(async () => {
      if (window.innerWidth !== lastWidth) {
        lastWidth = window.innerWidth;
        await closeQRScanner();
    }
  }, 500); // Adjust the debounce delay as needed

    /* This is if the user flips the phone to portrait or landscape,
       it will prevent a weird look of the camera */
    window.addEventListener('resize', handleResize);

    return () => { // Cleanup on de-mount
      closeQRScanner().catch((err) =>
        console.error('Error al cerrar el escáner.')
      );
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  // This should de-mount this component
  const goBack = async () => {
    await closeQRScanner(); // we close anyways even though the cleanup will try again
    setShowScanner(false);
  };

  // This is for scanning an Image file
  const handleFileChange = async (event) => {
    const imageFile = event.target.files[0];

    // Scan QR Code
    if (imageFile) {
      await closeQRScanner();
      scannerRef.current
        .scanFile(imageFile, false)
        .then((decodedText) => {
          // success, use decodedText
          //console.log(decodedText);
          fillData(decodedText);
          goBack();
        })
        .catch((err) => {
          // failure
          console.log(
            `Error al escanear la imagen. Por favor, suba una imagen clara del QR.`
          );
          setErrorMsg(
            'Error al escanear la imagen. Por favor, suba una imagen clara del QR.'
          );
        });
    }
  };

  // This opens the camera and starts scanning
  const openQRScanner = async () => {
    try{
      await closeQRScanner(); // Ensures no scanner is running

      // qrbox is a the region of the camera that is used for scanning.
      // This function sets that area dynamically
      const qrboxFunction = (viewfinderWidth, viewfinderHeight) => {
        const minEdgePercentage = 0.7;
        const minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        const qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
          width: qrboxSize,
          height: qrboxSize,
        };
      };

      scannerRef.current
        .start(
          { facingMode: 'environment' }, // Uses back camera
          {
            fps: 10,
            qrbox: qrboxFunction,
            aspectRatio: 1.777778 // 16:9
          },
          (decodedText, decodedResult) => {
            //console.log(decodedText);
            fillData(decodedText);
            goBack();
          },
          (errorMessage) => {
            // This is the stream of errors while scanning.
            // Usually is just along the lines of 'code not found'.
            //console.log(errorMessage)
          }
        )
        .then(() => { // This runs once when the scanner starts
          setIsOpening(false);
        })
        .catch((err) => {
          setIsOpening(false);
          console.error('No se pudo iniciar la fuente de video.');
          setErrorMsg('No se pudo iniciar la fuente de video.');
        });

    } catch (err){
      setIsOpening(false);
      console.error('Algo salió mal al abrir la cámara. Por favor, regrese o recargue la página.')
      setErrorMsg('Algo salió mal al abrir la cámara. Por favor, regrese o recargue la página.');
    }
  };

  // Debouncing the openQRScanner function to prevent rapid invocations
  const debouncedOpenQRScanner = debounce(openQRScanner, 500);

  // This will prompt the user to give access to the camera
  const requestPermissions = () => {
    setErrorMsg('');
    if (!isOpening) {
      setIsOpening(true);
      Html5Qrcode.getCameras()
        .then((devices) => {
          /*
            devices would be an array of objects of type:
            { id: 'id', label: 'label' }
            Since we are using { facingMode: 'environment' } on .start,
            we dont need that, but it is possible to allow the user to
            select the camera and pass the cameraId instead.
            (not { facingMode: devices[3].id }, just devices[3].id for example)
          */
          if (devices && devices.length) {
            debouncedOpenQRScanner();
            /* var cameraId = devices[0].id;
            debouncedOpenQRScanner(cameraId); */
          }
        })
        .catch((err) => {
          setErrorMsg('No se pudo obtener acceso a la cámara.');
          console.error('No se pudo obtener acceso a la cámara.');
          closeQRScanner(); // it could happen that a scanner was already active
          setIsOpening(false);
        });
    }
  };

  return (
    <div className='flex-center full-view-flexible qr-root'>
      <div className='qr-container'>
        <div id='reader' className='camera-frame'></div>
        <Loader active={isOpening} color={'white'} style={{top: '40%', position: 'absolute', marginInline: 10}}/>
        {errorMsg && <span className='error-message qr-error'>{errorMsg}</span>}
        <div className='qr-buttons-container'>
          <button className='qr-button' onClick={goBack}>
            Volver
          </button>
          <button
            className='qr-button'
            onClick={requestPermissions}
            disabled={isOpening}
          >
            Prender camara
          </button>
          <div style={{ minWidth: 165, maxWidth: 400, width: '100%' }}>
            <input
              type='file'
              ref={qrImageRef}
              accept='image/*'
              hidden
              onChange={handleFileChange}
            />
            <button
              className='qr-button'
              onClick={() => {
                qrImageRef.current?.click();
              }}
              style={{ textWrap: 'nowrap' }}
            >
              Cargar Imagen
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

// Debounce function to limit how often a function can be invoked
function debounce(func, wait) {
  let timeout;
  return function (...args) {
    clearTimeout(timeout);
    timeout = setTimeout(() => func.apply(this, args), wait);
  };
}

export default QRScanner;
