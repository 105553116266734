import { BASE_URL } from '../apiConfig';

const getHashValidService = {
  getHashValid: async ({
    moneyIn,
    moneyOut,
    period,
    validID,
    machineID,
    systemID
  }) => {
    try {
      const url = `${BASE_URL}/update_machine.php`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          moneyIn,
          moneyOut,
          period,
          validID,
          machineID,
          systemID
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return { success: 200, message: data.message, hash: data.hash };
      } else {
        // Handle specific HTTP error codes
        switch (response.status) {
          case 400:
            console.error('Parámetros incompletos.');
            return { error: 'Parámetros incompletos.' };
          case 404:
            console.error('No se encontraron resultados para la máquina especificada.');
            return { error: 'No se encontraron resultados para la máquina especificada.' };
          case 409:
            console.error('Máquina ya ha sido validada anteriormente.');
            return { error: 'Máquina ya ha sido validada anteriormente.' };
          default:
            console.error('Error del servidor, por favor intente más tarde.');
            return { error: 'Error del servidor, por favor intente más tarde.' };
        }
      }
    } catch (error) {
      console.error('Error, por favor intente más tarde.', error);
      return { error: 'Error, por favor intente más tarde.' };
    }
  },
};

export default getHashValidService;
