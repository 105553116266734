import { BASE_URL } from '../apiConfig';

const registerMachineService = {
  registerMachine: async ({ name, idLocation, boardKey, softwareVersion }) => {
    try {
      const url = `${BASE_URL}/register_machine.php`;
      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          idLocation,
          boardKey,
          softwareVersion,
        }),
      });

      if (response.ok) {
        const data = await response.json();
        return { success: 200, message: data.message };
      } else {
        // Handle specific HTTP error codes
        switch (response.status) {
          case 400:
            console.error('Parámetros incompletos.');
            return { error: 'Parámetros incompletos.' };
          case 409:
            console.error('Ya existe una máquina con este board key.');
            return { error: 'Ya existe una máquina con este board key.' };
          default:
            console.error('Error del servidor, por favor intente más tarde.');
            return { error: 'Error del servidor, por favor intente más tarde.' };
        }
      }
    } catch (error) {
      console.error('Error, por favor intente más tarde.', error);
      return { error: 'Error, por favor intente más tarde.' };
    }
  },
};

export default registerMachineService;
