import { useState } from 'react';
import getHashConfigService from '../../../../services/hashServices/getHashConfigService';
import QRScanner from '../../../QRScanner/QRScanner';

const MachineConfig = () => {

  const [showQRScanner, setShowQRScanner] = useState(false);

  const [input1, setInput1] = useState('');
  const [input2, setInput2] = useState('');
  const [input3, setInput3] = useState('');
  const [input4, setInput4] = useState('');
  const [input5, setInput5] = useState('');
  const [input6, setInput6] = useState('');
  const [outputText5, setOutputText5] = useState('');

  const handleOpenQR = () => {
    setShowQRScanner(true);
  };

  function fillData(dataString) {

    setInput1('');
    setInput2('');
    setInput3('');
    setInput4('');
    setInput5('');
    setInput6('');
    setOutputText5('');

    const rawInput1 = /Input:\s*(\d+)/;
    const rawInput2 = /Output:\s*(\d+)/;
    const rawInput4 = /Machine\s*ID:\s*([^,\n]+)/;
    const rawInput3 = /Valid\s*ID:\s*(\d+)/;
    const rawInput5 = /V:\s*([^,\n]+)/;
    const rawInput6 = /SH:\s*([^,\n]+)/;

    const matchInput1 = typeof dataString === 'string' ? dataString.match(rawInput1) : null;
    const matchInput2 = typeof dataString === 'string' ? dataString.match(rawInput2) : null;
    const matchInput4 = typeof dataString === 'string' ? dataString.match(rawInput4) : null;
    const matchInput3 = typeof dataString === 'string' ? dataString.match(rawInput3) : null;
    const matchInput5 = typeof dataString === 'string' ? dataString.match(rawInput5) : null;
    const matchInput6 = typeof dataString === 'string' ? dataString.match(rawInput6) : null;

    if (matchInput1) { setInput1(matchInput1[1]); }
    if (matchInput2) { setInput2(matchInput2[1]); }
    if (matchInput4) { setInput4(matchInput4[1]); }
    if (matchInput3) { setInput3(matchInput3[1]); }
    if (matchInput5) { setInput5(matchInput5[1]); }
    if (matchInput6) { setInput6(matchInput6[1]); }
  }

  const getHashConfig = async () => {
    try {
      const result = await getHashConfigService.getHashConfig({
        input1,
        input2,
        input4,
        input3,
        input5,
        input6
      });

      if (result.success) {

        console.log(result);
        setOutputText5(result.hash);
      } else {
        console.error(result.message, result.errorData);
      }

    } catch (error) { 
      console.error('Error durante el registro:', error);
    }
  };

  return (
    <>
      {showQRScanner ? (
        <QRScanner setShowScanner={setShowQRScanner} fillData={fillData}/>
    ) : (
      <div className='form-container'>
        <div className="white-container size-stretch text-center">
          <h1>Generar Código para Configuración</h1>
        </div>
        <br/>
        <div className="white-container size-big">
          <div>
            <input className='form-input' type="text" id="input1" placeholder="Ingreso" value={input1} onChange={(e) => setInput1(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input2" placeholder="Egreso" value={input2} onChange={(e) => setInput2(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input4" placeholder="MaquinaID" value={input4} onChange={(e) => setInput4(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input3" placeholder="Validador" value={input3} onChange={(e) => setInput3(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input5" placeholder="Version de Software" value={input5} onChange={(e) => setInput5(e.target.value)} /><br/>
            <input className='form-input' type="text" id="input6" placeholder="ID Sistema" value={input6} onChange={(e) => setInput6(e.target.value)} /><br/>
          </div>
        </div>
        <br/>
        <div className="white-container size-stretch">
          <div>
            <button className="submit-button light-green" onClick={getHashConfig}> 
              Generar Código de Validación
            </button>
            <button className="button-vertical submit-button blue" onClick={handleOpenQR}>
              Cargar Desde QR
            </button>
            <p id="outputText5" style={{ textAlign: 'center', fontWeight: 'bold' }}><h3>{"Código: " + outputText5}</h3></p>
          </div>
        </div>
      </div>
    )}
    </>
  );
};

export default MachineConfig;
