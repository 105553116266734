import { BASE_URL } from "./apiConfig";

const requestRoles = async () => {
    try {
        const response = await fetch(`${BASE_URL}/get_roles.php`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
            },
        });

        if (!response.ok) {
            // Handle HTTP errors
            switch (response.status) {
                case 404:
                    console.error('No se encontraron roles.');
                    return { error: 'No se encontraron roles.' };
                case 500:
                    console.error('Error del servidor al obtener roles.');
                    return { error: 'Error del servidor al obtener roles.' };
                case 405:
                    console.error('Método de solicitud no permitido.');
                    return { error: 'Método de solicitud no permitido.' };
                default:
                    console.error('Error del servidor.');
                    return { error: 'Error del servidor.' };
            }
        }

        // Response is OK
        const data = await response.json();

        if (data.success) {
            return { success: data };
        } else {
            console.error('Error al obtener roles.');
            return { error: 'Error al obtener roles.'}
        }
    } catch (error) {
        console.error('Error al obtener roles.');
        return { error: 'Error al obtener roles.'}
    }
};

export default requestRoles;
