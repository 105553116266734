import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';

import { AuthProvider } from './contexts/AuthContext.js';
import { DataProvider } from './contexts/DataContext.js';
import PrivateRoute from './components/PrivateRoute/PrivateRoute.js';

import Layout from "./components/Layout.js";
import LoginForm from './components/screens/Login/LoginForm.js';
import ForgotPasswordForm from './components/screens/ForgotPassword/ForgotPasswordForm.js';
import HomePage from './components/screens/Home/HomePage.js'; 

import Validation from "./components/screens/Codes/Validation/validation.js";
import MachineConfig from "./components/screens/Codes/MachineConfig/MachineConfig.js";
import Tournament from "./components/screens/Codes/Tournament/Tournament.js";

import MachineReview from "./components/screens/Reviews/MachineReview/MachineReview.js";
import LocalReview from "./components/screens/Reviews/LocalReview/LocalReview.js";
import UserReview from "./components/screens/Reviews/UserReview/UserReview.js";

import Journal from "./components/screens/Journal/Journal.js";

import NewBundles from "./components/screens/AdminPanel/NewBundles/NewBundles.js";
import NewLocal from "./components/screens/AdminPanel/NewLocal/NewLocal.js";
import NewUser from "./components/screens/AdminPanel/NewUser/NewUser.js";

import EditUser from "./components/screens/Reviews/EditUser/EditUser.js";
import EditMachine from "./components/screens/Reviews/EditMachine/EditMachine.js";
import EditLocal from "./components/screens/Reviews/EditLocal/EditLocal.js";

import Forbidden from "./components/screens/Forbidden/Forbidden.js";

import CashierPanel from './components/screens/Cashier/CashierPanel.js';
 

function App() {
  return (
    <AuthProvider>
      <DataProvider>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<LoginForm />} />
            <Route path="/login" element={<LoginForm />} />
            <Route path="/forgot-password" element={<ForgotPasswordForm />} />

            <Route path="/home" element={<PrivateRoute element={<Layout><HomePage /></Layout>} />} />

            <Route path="/cashier-panel" element={<PrivateRoute element={<CashierPanel />} />} />

            <Route path="/codes/valid" element={<PrivateRoute element={<Layout><Validation /></Layout>} />} />
            <Route path="/codes/config" element={<PrivateRoute element={<Layout><MachineConfig /></Layout>} />} />
            <Route path="/codes/tournament" element={<PrivateRoute element={<Layout><Tournament /></Layout>} />} />

            <Route path="/views/local-review" element={<PrivateRoute element={<Layout><LocalReview /></Layout>} />} />
            <Route path="/views/machine-review" element={<PrivateRoute element={<Layout><MachineReview /></Layout>} />} />
            <Route path="/views/users-review" element={<PrivateRoute element={<Layout><UserReview /></Layout>} />} />
            <Route path="/views/journal" element={<PrivateRoute element={<Layout><Journal /></Layout>} />} />

            <Route path="/views/users-review/edit-user/:userId" element={<PrivateRoute element={<Layout><EditUser /></Layout>} />} />
            <Route path="/views/machine-review/edit-machine/:machineId" element={<PrivateRoute element={<Layout><EditMachine /></Layout>} />} />
            <Route path="/views/local-review/edit-local/:locationId" element={<PrivateRoute element={<Layout><EditLocal /></Layout>} />} />

            <Route path="/admin-panel/new-bundles" element={<PrivateRoute element={<Layout><NewBundles /></Layout>} />} />
            <Route path="/admin-panel/new-local" element={<PrivateRoute element={<Layout><NewLocal /></Layout>} />} />
            <Route path="/admin-panel/new-user" element={<PrivateRoute element={<Layout><NewUser /></Layout>} />} />

            <Route path="/forbidden" element={<Layout><Forbidden /></Layout>} />
          </Routes>
        </BrowserRouter>
      </DataProvider>
    </AuthProvider>
  );
}

export default App;
