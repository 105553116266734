import { useState, useEffect, useRef } from 'react';
import { useData } from '../../../contexts/DataContext';
import { useAuth } from '../../../contexts/AuthContext';
import { BASE_URL } from '../../../services/apiConfig';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPersonWalkingDashedLineArrowRight } from '@fortawesome/free-solid-svg-icons';
import Loader from '../../Loader/Loader';
import QRScanner from '../../QRScanner/QRScanner';

import '../../../styles/MovingBackground.css';
import '../../../styles/misc/Forms.css';
import '../../../styles/misc/Data.css';

const normalizeDate = (dateString) => {
  // Split the input date into components and convert them to integers to remove leading zeros
  const [day, month, year] = dateString.split('/').map(part => parseInt(part, 10));
  // Return the normalized date string in dd/mm/yyyy format
  return `${day}/${month}/${year}`;
};

const CashierPanel = () => {
  const { logout, isAuthenticated } = useAuth();
  const { state } = useData();
  const { userData } = state;

  useEffect(() => {
    if (!isAuthenticated || userData?.role_id !== 5) {
      logout();
    }
  }, [isAuthenticated, userData, logout]);

  const errRef = useRef();

  const [errMsg, setErrMsg] = useState('');
  const [successMsg, setSuccessMsg] = useState('');
  const [submitLoading, setSubmitLoading] = useState(false);
  const [retrieveLoading, setRetrieveLoading] = useState(false);

  const [showQRScanner, setShowQRScanner] = useState(false);
  const [showCashierData, setShowCashierData] = useState(false);

  const [filterDate, setFilterDate] = useState('');
  const [cashierData, setCashierData] = useState([]);
  const [filteredCashierData, setFilteredCashierData] = useState([]);

  const [amount, setAmount] = useState('');
  const [moneyIn, setMoneyIn] = useState('');
  const [moneyOut, setMoneyOut] = useState('');
  const [machineID, setMachineID] = useState('');
  const [validID, setValidID] = useState('');
  const [period, setPeriod] = useState('');
  const [expirationDate, setExpirationDate] = useState('');
  const [softwareVersion, setSoftwareVersion] = useState('');
  const [systemHash, setSystemHash] = useState('');
  const [curGame, setCurGame] = useState('');

  const filterCurrentDate = () => {
    const currentDate = new Date().toLocaleDateString('es-ES');
    setFilterDate(currentDate);
  };

  const clearDateFilter = () => {
    setFilterDate('');
  };

  useEffect(() => {
    filterCashierData();
  }, [filterDate, cashierData]);

  const filterCashierData = () => {
    if (!filterDate) {
      setFilteredCashierData(cashierData);
      return;
    }
    
    const normalizedFilterDate = normalizeDate(filterDate);

    const filtered = cashierData.filter(row => {
      const rowDate = new Date(row.UPDATE_TIMESTAMP).toLocaleDateString('es-ES');
      return rowDate === normalizedFilterDate;
    });

    setFilteredCashierData(filtered);
  };

  function fillData(dataString) {
    // Data could already be there, if new QR is scanned and
    // some fields don't match the pattern, the old data could
    // stay, so for that we clear it here.
    setAmount('');
    setMoneyIn('');
    setMoneyOut('');
    setMachineID('');
    setValidID('');
    setPeriod('');
    setExpirationDate('');
    setSoftwareVersion('');
    setSystemHash('');
    setCurGame('');

    const amountPattern = /Amount:\s*(\d+),/;
    const moneyInPattern = /In:\s*(\d+),/;
    const moneyOutPattern = /Out:\s*(\d+),/;
    const machineIDPattern = /ID:\s*([^,]+),/;
    const expireDatePattern = /Expire\s*Date:\s*([^,]+),/;
    const softwareVersionPattern = /V:\s*([\d.]+),/;
    const systemHashPattern = /SH:\s*([^,]+),/;
    const curGamePattern = /CurGame:\s*([^,]+),/;
    const validIDPattern = /Valid\s*ID:\s*([^,]+),/;

    const matchAmount = typeof dataString === 'string' ? dataString.match(amountPattern) : null;
    const matchMoneyIn = typeof dataString === 'string' ? dataString.match(moneyInPattern) : null;
    const matchMoneyOut = typeof dataString === 'string' ? dataString.match(moneyOutPattern) : null;
    const matchMachineID = typeof dataString === 'string' ? dataString.match(machineIDPattern) : null;
    const matchExpireDate = typeof dataString === 'string' ? dataString.match(expireDatePattern) : null;
    const matchSoftwareVersion = typeof dataString === 'string' ? dataString.match(softwareVersionPattern) : null;
    const matchSystemHash = typeof dataString === 'string' ? dataString.match(systemHashPattern) : null;
    const matchCurGame = typeof dataString === 'string' ? dataString.match(curGamePattern) : null;
    const matchValidID = typeof dataString === 'string' ? dataString.match(validIDPattern) : null;

    if (matchAmount) {
      setAmount(matchAmount[1]);
    }

    if (matchMoneyIn) {
      setMoneyIn(matchMoneyIn[1]);
    }

    if (matchMoneyOut) {
      setMoneyOut(matchMoneyOut[1]);
    }

    if (matchMachineID) {
      setMachineID(matchMachineID[1]);
    }

    if (matchExpireDate) {
      const expireDate = new Date(matchExpireDate[1]);
      const formattedDate = expireDate.toISOString().split('T')[0];
      setExpirationDate(formattedDate);

      // Calculate period
      const today = new Date();
      const diffTime = Math.abs(expireDate - today);
      const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));
      setPeriod(diffDays);
    }

    if (matchSoftwareVersion) {
      setSoftwareVersion(matchSoftwareVersion[1]);
    }

    if (matchSystemHash) {
      setSystemHash(matchSystemHash[1]);
    }

    if (matchCurGame) {
      setCurGame(matchCurGame[1]);
    }

    if (matchValidID) {
      setValidID(matchValidID[1]);
    }
  }

  const clearSuccessSetError = (message) => {
    setSuccessMsg('');
    setErrMsg(message);
  };


  const handleCloseCashierData = () => {
    setCashierData([]);
    setShowCashierData(false);
  };

  const handleOpenQR = () => {
    clearSuccessSetError('');
    setShowCashierData(false);
    setShowQRScanner(true);
  };

  const submitData = async () => {
    if (!submitLoading && !retrieveLoading) {
      if (
        !amount ||
        !moneyIn ||
        !moneyOut ||
        !machineID ||
        !validID ||
        !period ||
        !expirationDate ||
        !softwareVersion ||
        !systemHash ||
        !curGame
      ) {
        clearSuccessSetError('QR inválido, faltan datos.');
        errRef?.current?.focus();
        return;
      }
      clearSuccessSetError('');
      setSubmitLoading(true);
      const requestData = {
        amount,
        moneyIn,
        moneyOut,
        machineID,
        validID,
        period,
        expirationDate,
        softwareVersion,
        systemHash,
        cashierUsername: userData.username,
        curGame,
      };

      try {
        const response = await fetch(`${BASE_URL}/cashier_machine_update.php`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setAmount('');
          setMoneyIn('');
          setMoneyOut('');
          setMachineID('');
          setValidID('');
          setPeriod('');
          setExpirationDate('');
          setSoftwareVersion('');
          setSystemHash('');
          setCurGame('');
          setSuccessMsg('Máquina actualizada exitosamente.');
        } else {
          switch (response.status) {
            case 400:
              clearSuccessSetError('Formato de solicitud incorrecto.');
              break;
            case 409:
              clearSuccessSetError('QR inválido. El QR ya ha sido utilizado.');
              break;
            case 404:
              clearSuccessSetError('No se encontraron resultados para esta máquina.');
              break;
            default:
              clearSuccessSetError('Error del servidor, por favor intente más tarde.');
              break;
          }
        }
      } catch (error) {
        console.error('Error, por favor intente más tarde.');
        clearSuccessSetError('Error, por favor intente más tarde.');
      } finally {
        setSubmitLoading(false);
      }
    }
  };

  const retrieveCashierData = async () => {
    if(!submitLoading && !retrieveLoading) {
      clearSuccessSetError('');
      setRetrieveLoading(true);
      try {
        const response = await fetch(
          `${BASE_URL}/cashier_machine_update.php?username=${encodeURIComponent(
            userData.username
          )}`,
          {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            },
          }
        );

        if (response.ok) {
          const data = await response.json();
          if (data.success) {
            setShowCashierData(true);
            setCashierData(data.data);
          } else {
            clearSuccessSetError('No se encontraron registros para este usuario.');
          }
        } else {
          switch (response.status) {
            case 400:
              clearSuccessSetError('El nombre de usuario es obligatorio.');
              break;
            case 404:
              clearSuccessSetError('No se encontraron registros para este usuario.');
              break;
            default:
              clearSuccessSetError('Error del servidor, por favor intente más tarde.');
              break;
          }
        }
      } catch (error) {
        console.error('Error, por favor intente más tarde.');
        clearSuccessSetError('Error, por favor intente más tarde.');
      } finally {
        setRetrieveLoading(false);
      }
    }
  };

  return (
    <>
      <div className='moving-background'></div>
      {showQRScanner ? (
        <QRScanner setShowScanner={setShowQRScanner} fillData={fillData} />
      ) : showCashierData ? (
        <div className='flex-center full-view-flexible'>
          <div className='data-form pad-vertical-no-layout'>
            <div className='white-container size-stretch text-center'>
              <h1>Transacciones de caja</h1>
            </div>
            <br />
            <div className='white-container' style={{ display: 'flex', flexDirection: 'column', padding: 30}}>
              <div className='flex-center' style={{gap: 20}}>
                <h3>Filtrar fecha: </h3>
                <input
                  className='form-input'
                  style={{marginBottom: 0}}
                  type='text'
                  id='date-filter'
                  placeholder='DD/MM/YYYY'
                  value={filterDate}
                  onChange={(e) => setFilterDate(e.target.value)}
                />
              </div>
              <div style={{gap: 20, display: 'flex', alignItems: 'center'}}>
                <span className='filter-text' onClick={filterCurrentDate}>Fecha Actual</span>
                <span className='filter-text' onClick={clearDateFilter}>Borrar Fecha</span>
              </div>
            </div>
            <br />
            <div className='dynamic-data-container'>
              <table className='data-table'>
                <thead>
                  <tr>
                    <th>Juego</th>
                    <th>Monto</th>
                    <th>Ingreso</th>
                    <th>Egreso</th>
                    <th>Tiempo (días)</th>
                    <th>Fecha de expiración</th>
                    <th>Ingresado él</th>
                  </tr>
                </thead>
                <tbody>
                  {filteredCashierData.map((row, indx) => {
                    const expirationDate = new Date(
                      row?.EXPIRATION_DATE
                    ).toLocaleDateString('es-ES');
                    const updateTimestamp = new Date(row?.UPDATE_TIMESTAMP).toLocaleString('es-ES', {
                      day: '2-digit',
                      month: '2-digit',
                      year: 'numeric',
                      hour: '2-digit',
                      minute: '2-digit',
                      second: '2-digit',
                    });

                    return (
                      <tr key={indx}>
                        <td>{row?.GAME_NAME}</td>
                        <td>{row?.AMOUNT_PAYED}</td>
                        <td>{row?.MONEY_IN}</td>
                        <td>{row?.MONEY_OUT}</td>
                        <td>{row?.PERIOD}</td>
                        <td>{expirationDate}</td>
                        <td>{updateTimestamp}</td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
            <br />
            <div className='white-container size-stretch'>
              <div>
                <button
                  className={'button-vertical submit-button'}
                  onClick={handleCloseCashierData}
                >
                  Volver
                </button>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div className='flex-center full-view-flexible'>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              position: 'absolute',
              top: 15,
              left: 10,
              gap: 10,
              cursor: 'pointer',
            }}
            onClick={logout}
          >
            <FontAwesomeIcon
              icon={faPersonWalkingDashedLineArrowRight}
              style={{ transform: 'scaleX(-1)', color: 'white' }}
              size='lg'
            />
            <span style={{ color: 'white', fontSize: 20 }}>Cerrar Sesión</span>
          </div>
          <div className='data-form pad-vertical-no-layout'>
            <div className='white-container size-stretch text-center'>
              <h1>Escanear código QR</h1>
              {errMsg && <span className='error-message'>{errMsg}</span>}
              {successMsg && (
                <span className='success-message'>{successMsg}</span>
              )}
            </div>
            <br />
            <div className='data-container'>
              <table className='data-table flex-table'>
                <tbody>
                  <tr>
                    <th>Monto</th>
                    <td>{amount}</td>
                  </tr>
                  <tr>
                    <th>Ingreso</th>
                    <td>{moneyIn}</td>
                  </tr>
                  <tr>
                    <th>Egreso</th>
                    <td>{moneyOut}</td>
                  </tr>
                  <tr>
                    <th>Tiempo (días)</th>
                    <td>{period}</td>
                  </tr>
                  <tr>
                    <th>Fecha de expiración</th>
                    <td>{expirationDate}</td>
                  </tr>
                  <tr>
                    <th>Juego</th>
                    <td>{curGame}</td>
                  </tr>
                </tbody>
              </table>
            </div>
            <br />
            <div className='white-container size-stretch'>
              <div>
                <button
                  className={`button-vertical submit-button ${
                    submitLoading || retrieveLoading ? 'button-disabled' : ''
                  }`}
                  style={{ position: 'relative' }}
                  onClick={retrieveCashierData}
                  disabled={submitLoading || retrieveLoading}
                >
                  Info cajero
                  <Loader
                    active={retrieveLoading}
                    style={{ position: 'absolute', right: 15, bottom: 8 }}
                  />
                </button>
                <button
                  className={`button-vertical submit-button ${
                    submitLoading || retrieveLoading ? 'button-disabled' : ''
                  }`}
                  onClick={handleOpenQR}
                  disabled={submitLoading || retrieveLoading}
                >
                  Abrir escáner de QR
                </button>
                <button
                  className={`submit-button light-green ${
                    submitLoading || retrieveLoading ? 'button-disabled' : ''
                  }`}
                  style={{ position: 'relative' }}
                  disabled={submitLoading || retrieveLoading}
                  onClick={submitData}
                >
                  Enviar datos
                  <Loader
                    active={submitLoading}
                    style={{ position: 'absolute', right: 15, bottom: 8 }}
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default CashierPanel;
