import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { updateMachineDataService, getMachineDataService } from '../../../../services/CRUD/MachineDataService';

const EditMachine = () => {
  const { machineId } = useParams();
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    id: '',
    name: '',
    active: '',
    locationName: '',
    boardKey: '',
    amountPayed: '',
    moneyIn: '',
    moneyOut: '',
    period: '',
    expirationDate: '',
    lastMoneyInOutUpdate: '',
    softwareVersion: '',
    tournamentCounter: '',
  });

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await getMachineDataService(machineId);
        if (response.Success && response.Success.length > 0) {
          const machineData = response.Success[0];
          setFormData({
            id: machineData.ID,
            name: machineData.machine_game_name,
            active: machineData.ACTIVE,
            locationName: machineData.location_name,
            boardKey: machineData.BOARD_KEY,
            amountPayed: machineData.AMOUNT_PAYED,
            moneyIn: machineData.MONEY_IN,
            moneyOut: machineData.MONEY_OUT,
            period: machineData.PERIOD,
            expirationDate: machineData.EXPIRATION_DATE,
            lastMoneyInOutUpdate: machineData.LAST_MONEY_IN_OUT_UPDATE,
            softwareVersion: machineData.SOFTWARE_VERSION,
            tournamentCounter: machineData.TOURNAMENT_COUNTER,
          });
        } else {
          console.error('Error al obtener los datos de la máquina:', response.message);
        }
      } catch (error) {
        console.error('Error durante la solicitud:', error);
      }
    };

    fetchData();
  }, [machineId]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const result = await updateMachineDataService(formData);

    if (result.success) {
      console.log('Máquina actualizada:', result.message);
      navigate(`/views/machine-review/`);
    } else {
      console.error('Error al actualizar la máquina:', result.message, result.errorData);
    }
  };

  return (
    <div className='form-container'>
      <div className="white-container size-stretch text-center">
        <h1>Editar Máquina</h1>
      </div>
      <br/>
      <form onSubmit={handleSubmit} className='white-container size-big' autoComplete="off">
        <label>
          Nombre del Juego:
          <input className='form-input form-disabled' type='text' name='name' value={formData.name} onChange={handleChange} readOnly required autoComplete="off" />
        </label>
        <label>
          Nombre de la Ubicación:
          <input className='form-input form-disabled' type='text' name='locationName' value={formData.locationName} onChange={handleChange} readOnly required autoComplete="off" />
        </label>
        <label>
          Clave de la Tabla:
          <input className='form-input form-disabled' type='text' name='boardKey' value={formData.boardKey} onChange={handleChange} readOnly required autoComplete="off" />
        </label>
        <label>
          Monto Pagado:
          <input className='form-input' type='number' name='amountPayed' value={formData.amountPayed} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Dinero en:
          <input className='form-input' type='number' name='moneyIn' value={formData.moneyIn} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Dinero fuera:
          <input className='form-input' type='number' name='moneyOut' value={formData.moneyOut} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Periodo:
          <input className='form-input' type='number' name='period' value={formData.period} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Fecha de Expiración:
          <input className='form-input' type='date' name='expirationDate' value={formData.expirationDate} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Última Actualización de Dinero:
          <input className='form-input' type='text' name='lastMoneyInOutUpdate' value={formData.lastMoneyInOutUpdate} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Versión del Software:
          <input className='form-input' type='text' name='softwareVersion' value={formData.softwareVersion} onChange={handleChange} required autoComplete="off" />
        </label>
        <label>
          Contador de Torneos:
          <input className='form-input' type='number' name='tournamentCounter' value={formData.tournamentCounter} onChange={handleChange} required autoComplete="off" />
        </label>
        <button className='submit-button blue' type='submit'>
          Actualizar Máquina
        </button>
      </form>
    </div>
  );
};

export default EditMachine;
