import { useState } from 'react';
import getHashValidService from '../../../../services/hashServices/getHashValidService.js';
import { useData } from '../../../../contexts/DataContext';
import QRScanner from '../../../QRScanner/QRScanner.js';
import Checkbox from '../../../Checkbox/Checkbox.js';

const Validation = () => {
  const { state } = useData();
  const { userData } = state;
  
  const [errMsg, setErrMsg] = useState('');
  const [firstValidation, setFirstValidation] = useState(false);
  const [showQRScanner, setShowQRScanner] = useState(false);

  const [moneyIn, setMoneyIn] = useState('');
  const [moneyOut, setMoneyOut] = useState('');
  const [period, setPeriod] = useState('');
  const [validID, setValidID] = useState('');
  const [machineID, setMachineID] = useState('');
  const [systemID, setSystemID] = useState('');
  const [hashResult, setHashResult] = useState('');
  const [expirationDate, setExpirationDate] = useState('');

  const handleOpenQR = () => {
    setErrMsg('');
    setShowQRScanner(true);
  };

  function fillData(dataString) {

    setMoneyIn('');
    setMoneyOut('');
    setPeriod('');
    setValidID('');
    setMachineID('');
    setSystemID('');
    setHashResult('');
    setExpirationDate('');

    const moneyInPattern = /Input:\s*(\d+)/;
    const moneyOutPattern = /Output:\s*(\d+)/;
    const machineIDPattern = /Machine\s*ID:\s*([^,\n]+)/;
    const validIDPattern = /Valid\s*ID:\s*(\d+)/;
    const expireDatePattern = /Expire\s*Date:\s*([^,\n]+)/;

    const matchMoneyIn = typeof dataString === 'string' ? dataString.match(moneyInPattern) : null;
    const matchMoneyOut = typeof dataString === 'string' ? dataString.match(moneyOutPattern) : null;
    const matchMachineID = typeof dataString === 'string' ? dataString.match(machineIDPattern) : null;
    const matchValidID = typeof dataString === 'string' ? dataString.match(validIDPattern) : null;
    const matchExpireDate = typeof dataString === 'string' ? dataString.match(expireDatePattern) : null;

    if (matchMoneyIn) {
      setMoneyIn(matchMoneyIn[1]);
    }

    if (matchMoneyOut) {
      setMoneyOut(matchMoneyOut[1]);
    }

    if (matchMachineID) {
      setMachineID(matchMachineID[1]);
    }

    if (matchValidID) {
      setValidID(matchValidID[1]);
    }

    if (matchExpireDate) {
      const formattedDate = new Date(matchExpireDate[1]).toISOString().split('T')[0];
      setExpirationDate(formattedDate);
    }
  };

  const getValidationHash = async () => {
    setErrMsg('');
    try {

      const requestData = {
        moneyIn,
        moneyOut,
        period,
        validID,
        machineID,
        systemID
      };
  
      if (firstValidation) {
        requestData.moneyIn = '0';
        requestData.moneyOut = '0';
      }
      
      const result = await getHashValidService.getHashValid(requestData);

      if (result.success) {
        setHashResult(result.hash);
      }
      else {
        setErrMsg(result.error);
      }

    } catch (error) { 
      console.error('Error durante la validación.');
      setErrMsg('Error durante la validación.');
    }
  };


  const updateDateFromInt = (e) => {
    var inputValue = parseInt(e.target.value, 10);
    if (!isNaN(inputValue) && inputValue >= 0) {
      inputValue = Math.max(inputValue, 0);
      inputValue = Math.min(inputValue, userData.max_valid_days);

      setPeriod(inputValue);
      const today = new Date();
      today.setDate(today.getDate() + inputValue);
      const formattedDate = today.toISOString().split('T')[0];
      setExpirationDate(formattedDate);
    } else {
      setPeriod('');
      setExpirationDate('');
    }
  };

  const updateNumberFromDate = (newDate) => {
    const today = new Date();
    const selectedDate = new Date(newDate);
    if (selectedDate >= today) {
      const diffInDays = Math.floor((selectedDate - today) / (24 * 60 * 60 * 1000));
      setPeriod(diffInDays);
    } else {
      setPeriod(0);
    }
  };

  const updateDate = (event) => {
    let newDate = new Date(event.target.value);
    newDate.setDate(newDate.getDate() + 1); // Agregar un día
  
    const today = new Date();
    const maxDate = new Date();
    maxDate.setDate(today.getDate() + userData.max_valid_days);
  
    if (newDate < today) {
      newDate = today;
    } else if (newDate >= maxDate) {
      newDate = maxDate;
    }
  
    const formattedDate = [newDate.getFullYear(), ('0' + (newDate.getMonth() + 1)).slice(-2), ('0' + newDate.getDate()).slice(-2)].join('-')
  
    setExpirationDate(formattedDate);
    updateNumberFromDate(newDate);
  };

  return (
    <>
      {showQRScanner ? (
        <QRScanner setShowScanner={setShowQRScanner} fillData={fillData}/>
      ) : (
        <div className='form-container'>
          <div className='white-container size-stretch text-center'>
            <h1>Sistema de validación de Máquinas</h1>
            {errMsg && <span className='error-message'>{errMsg}</span>}
          </div>
          <br />
          <div className='white-container size-big'>
            <div>
              <div style={{display: 'flex', alignItems: 'center', gap: 10}}>
                <span>Primera Validación?</span>
                <Checkbox state={firstValidation} setState={setFirstValidation}/>
              </div>
              <br />
              {!firstValidation &&
              <>
                <input
                  className='form-input'
                  type='text'
                  id='moneyIn'
                  placeholder='Entrar Ingreso'
                  value={moneyIn}
                  onChange={(e) => setMoneyIn(e.target.value)}
                />
                <br />
                <input
                  className='form-input'
                  type='text'
                  id='moneyOut'
                  placeholder='Entrar Egreso'
                  value={moneyOut}
                  onChange={(e) => setMoneyOut(e.target.value)}
                />
                <br />
              </>
              }
              <input
                className='form-input'
                type='text'
                id='machineID'
                placeholder='Entrar MaquinaID'
                value={machineID}
                onChange={(e) => setMachineID(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='validID'
                placeholder='Entrar Validador'
                value={validID}
                onChange={(e) => setValidID(e.target.value)}
              />
              <br />
              <input
                className='form-input'
                type='number'
                id='period'
                placeholder='Entrar Tiempo'
                value={period}
                onInput={updateDateFromInt}
                min='0'
                max={userData.max_valid_days}
              />
              <br />
              <input
                className='form-input'
                type='date'
                id='expirationDate'
                name='expirationDate'
                onChange={updateDate}
                value={expirationDate}
              />
              <br />
              <input
                className='form-input'
                type='text'
                id='systemID'
                placeholder='ID Sistema'
                value={systemID}
                onChange={(e) => setSystemID(e.target.value)}
              />
              <br />
            </div>
          </div>
          <br />
          <div className='white-container size-stretch'>
            <div>
              <button className='submit-button light-green' onClick={getValidationHash}>
                Generar Código de Validación
              </button>
              <button
                className='button-vertical submit-button blue'
                onClick={handleOpenQR}
              >
                Cargar Desde QR
              </button>
              <p
                id='hashResult'
                style={{ textAlign: 'center', fontWeight: 'bold' }}
              >
                <>{'Código: ' + hashResult}</>
              </p>
            </div>
          </div>
        </div>
      )}
    </>
  );

};

export default Validation;