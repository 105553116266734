import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';

import './Checkbox.css';

const Checkbox = ({ state, setState }) => {
  const onBoxClick = () => {
    setState((prev) => !prev);
  };
  return (
    <button
      className={`checkbox ${state ? 'checkbox-active' : 'checkbox-inactive'}`}
      onClick={onBoxClick}
    >
      <FontAwesomeIcon
        icon={state ? faCheck : faTimes}
        color={state ? 'white' : 'black'}
      />
    </button>
  );
};

export default Checkbox;
